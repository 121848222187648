import React from 'react'
import { colors } from '../variables'
import { minWidth } from '../breakpoints'

export default ({ items, floating = true, boxed = false, styles }) => (
  <ul
    css={[
      {
        listStyle: 'none',
        marginLeft: 0,
        [minWidth.small]: {
          display: floating ? 'flex' : 'block',
          flexWrap: 'wrap',
        },
      },
      styles,
    ]}
  >
    {items.map((item, index) => (
      <li
        css={{
          alignItems: 'center',
          display: 'flex',
          marginBottom: 5,
          [minWidth.small]: {
            paddingRight: floating ? 20 : 0,
            width: floating ? '50%' : '100%',
          },
        }}
        key={index}
      >
        <div
          css={{
            marginLeft: boxed ? 2 : 0,
            marginTop: boxed ? -7 : -3,
            marginRight: 12,
            transform: 'rotate(-45deg) translateX(1px)',
          }}
        >
          <div
            css={{
              background: item.indexOf('-') == 0 ? 'transparent' : colors.green,
              borderRadius: 1,
              marginBottom: -4,
              height: 10,
              width: 4,
            }}
          />
          <div
            css={{
              background: item.indexOf('-') == 0 ? 'transparent' : colors.green,
              borderRadius: 1,
              height: 4,
              width: 15,
            }}
          />
        </div>
        {boxed && (
          <div
            css={{
              background: '#eee',
              borderRadius: 3,
              height: 14,
              marginTop: 1,
              position: 'absolute',
              width: 14,
              zIndex: -1,
            }}
          />
        )}
        {item.replace('-', '').trim()}
      </li>
    ))}
  </ul>
)
