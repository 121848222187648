import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/core'
import Wrapper from '../components/wrapper'
import Itineraries from '../components/itineraries'
import { minWidth } from '../breakpoints'
import { padding } from '../distances'

export default ({ children, background, itineraries }) => (
  <StaticQuery
    query={graphql`
      query {
        adventure: file(relativePath: { eq: "adventure.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1600, maxHeight: 1600, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        road: file(relativePath: { eq: "road.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1600, maxHeight: 1600, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={(data) => (
      <div
        css={[
          css`
            ${minWidth.laptop} {
              background: url('${data[background].childImageSharp.fluid.src}')
                no-repeat top right;
              background-size: 40%;
            }

            ${minWidth.full} {
              background-size: 50%;
            }
          `,
          padding('top'),
        ]}
      >
        <Wrapper>
          <div
            css={css`
              margin-bottom: 3rem;

              ${minWidth.laptop} {
                margin-bottom: 4.75rem;
                max-width: 660px;
              }
            `}
          >
            {children}
          </div>
          <Itineraries data={itineraries} />
        </Wrapper>
      </div>
    )}
  />
)
