import React from 'react'
import { css } from '@emotion/core'
import font from '../fonts'
import { colors } from '../variables'
import Wrapper from '../components/wrapper'
import { minWidth } from '../breakpoints'
import { margin } from '../distances'
import Color from 'color'

export default ({ dark, withMargin, photo, text }) => {
  const background = dark ? colors.cyan : colors.lightBlue

  return (
    <div
      css={[
        css`
          background: ${background};
          position: relative;
        `,
        withMargin ? margin('bottom') : null,
      ]}
    >
      <div
        css={css`
          background: url('${photo.src}') 50% / cover;
          height: 50%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;

          ${minWidth.tablet} {
            height: 100%;
            width: 50%;
          }
        `}
      />
      <div
        css={css`
          background: linear-gradient(
            to top,
            ${background} 15%,
            ${Color(background).alpha(0).string()}
          );
          top: 18%;
          height: 33%;
          left: 0;
          position: absolute;
          width: 100%;

          ${minWidth.tablet} {
            background: linear-gradient(
              to left,
              ${background} 15%,
              ${Color(background).alpha(0).string()}
            );
            height: 100%;
            left: 18%;
            top: 0;
            width: 33%;
          }
        `}
      />
      <Wrapper
        styles={css`
          ${minWidth.tablet} {
            display: flex;
            justify-content: flex-end;
          }
        `}
      >
        <blockquote
          css={css`
            color: ${dark ? 'white' : colors.cyan};
            font-family: ${font('Arvo')};
            font-size: 1.4em;
            line-height: 1.3;
            margin: 0;
            padding: 12.5rem 0 2.5rem 0;
            position: relative;

            ${minWidth.medium} {
              padding-bottom: 3.75rem;
            }

            ${minWidth.tablet} {
              padding: 7.5rem 0;
              width: 50%;
            }

            ${minWidth.laptop} {
              font-size: 1.8em;
            }
          `}
        >
          “{text}”
        </blockquote>
      </Wrapper>
    </div>
  )
}
