import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import Button from '../components/button'
import Thin from '../components/thin'
import { minWidth } from '../breakpoints'
import { margin } from '../distances'

const itineraryDistance = { small: 40, large: 60 }

const Itinerary = ({ image, title, subtitle, description, url }) => {
  const imageStyle = (hover) => css`
    background: gray url('${image.childImageSharp.fixed.src}') 50% / cover;
    display: block;
    height: 180px;
    transition: transform 0.2s ease-out;
    width: 100%;

    &:hover {
      transform: ${hover ? 'scale(1.05)' : 'none'};
    }

    ${minWidth.laptop} {
      height: 240px;
    }
  `

  return (
    <div
      css={css`
        box-shadow: 0 2px 13px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        width: 100%;

        ${minWidth.medium} {
          margin-right: ${itineraryDistance.small}px;
          width: calc(50% - ${itineraryDistance.small}px);
        }

        ${minWidth.tablet} {
          margin-bottom: 2.5rem;
        }

        ${minWidth.laptop} {
          margin-bottom: 3.33rem;
          margin-right: ${itineraryDistance.small}px;
          width: calc(${100 / 3}% - ${itineraryDistance.small}px);
        }

        @media (min-width: 1320px) {
          margin-right: ${itineraryDistance.large}px;
          width: calc(${100 / 3}% - ${itineraryDistance.large}px);
        }
      `}
    >
      <div
        css={css`
          flex-shrink: 0;
          overflow: hidden;
        `}
      >
        {url ? (
          <Link to={`/${url}`} css={imageStyle(true)} />
        ) : (
          <div css={imageStyle(false)} />
        )}
      </div>
      <div
        css={css`
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          padding: 1.8rem 1.67rem 2.25rem;
        `}
      >
        <div
          css={css`
            flex-grow: 1;
            max-width: 100%; // IE11 flexbug
          `}
        >
          <h3
            css={css`
              line-height: 1.25;
              margin-bottom: 0.2em;
            `}
          >
            {title}
          </h3>
          {subtitle && <Thin>{subtitle}</Thin>}
          {description && (
            <Thin
              styles={css`
                font-size: 1.1em;
                line-height: 1.5;
                margin-top: 0.8em;
              `}
            >
              {description}
            </Thin>
          )}
        </div>
        {url && (
          <Button
            styles={css`
              margin-top: 1.7rem;
            `}
            to={`/${url}`}
            small
          >
            Route bekijken
          </Button>
        )}
      </div>
    </div>
  )
}

export default ({ data }) => (
  <div css={margin('bottom')}>
    <div
      css={css`
        display: flex; // Initial flex to prevent margin collapse
        flex-direction: column;
        margin-bottom: -2rem;

        ${minWidth.medium} {
          flex-direction: row;
          flex-wrap: wrap;
          margin-right: -${itineraryDistance.small}px;
        }

        ${minWidth.tablet} {
          margin-bottom: -2.5rem;
        }

        ${minWidth.laptop} {
          margin-bottom: -3.33rem;
        }

        @media (min-width: 1320px) {
          margin-right: -${itineraryDistance.large}px;
        }
      `}
    >
      {data.map((itinerary, i) => (
        <Itinerary
          key={i}
          image={itinerary.image}
          title={itinerary.title}
          subtitle={itinerary.subtitle}
          description={itinerary.description}
          url={itinerary.url}
        />
      ))}
    </div>
  </div>
)
