import React from 'react'
import Wrapper from '../components/wrapper'
import { margin } from '../distances'
import BookingButton from './booking-button'
import Button from './button'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Anchor from './anchor'
import Checklist from './checklist'
import { minWidth } from '../breakpoints'

export default ({ description }) => (
  <StaticQuery
    query={graphql`
      query {
        intro: markdownRemark(fileAbsolutePath: { glob: "**/intro.md" }) {
          settings: frontmatter {
            title
            description
            pros
          }
        }
        gallery: file(relativePath: { eq: "intro-image.jpg" }) {
          image: childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={({ intro: { settings }, gallery }) => (
      <Wrapper
        styles={[
          {
            [minWidth.laptop]: {
              display: 'flex',
            },
          },
          margin('bottom'),
        ]}
      >
        <div
          css={{
            marginBottom: 40,
            [minWidth.laptop]: {
              flexBasis: 540,
              marginBottom: 0,
              marginRight: 60,
            },
          }}
        >
          <h2>{settings.title}</h2>
          {(description || settings.description).split("\n\n").map((text, index) => <p key={index}>{text}</p>)}
          <Checklist floating={false} items={settings.pros.split("\n")} />
          <div
            css={{
              marginBottom: -10,
            }}
          >
            <BookingButton
              styles={{
                marginBottom: 10,
                marginRight: 20,
              }}
            >
              Direct boeken
            </BookingButton>
            <Anchor
              target="#information"
              styles={{
                display: 'inline-flex',
              }}
            >
              <Button color="white">Meer over de camper</Button>
            </Anchor>
          </div>
        </div>
        <div
          css={{
            cursor: 'pointer',
            margin: '0 auto',
            maxWidth: 600,
            width: '100%',
            [minWidth.laptop]: {
              flexBasis: 600,
            },
          }}
        >
          <Anchor target="#gallery">
            <Img fluid={gallery.image.fluid} />
          </Anchor>
        </div>
      </Wrapper>
    )}
  />
)
