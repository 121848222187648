import React from 'react'
import Wrapper from './wrapper'
import BookingButton from './booking-button'
import { StaticQuery, graphql } from 'gatsby'
import { minWidth } from '../breakpoints'
import { css } from '@emotion/core'
import { padding, footerDistance } from '../distances'

export default () => (
  <StaticQuery
    query={graphql`
      query {
        booking: markdownRemark(fileAbsolutePath: { glob: "**/booking.md" }) {
          settings: frontmatter {
            title
            description
          }
        }
        background: file(relativePath: { eq: "booking.jpg" }) {
          image: childImageSharp {
            fluid(maxWidth: 1960, maxHeight: 1440, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={({ booking: { settings }, background }) => (
      <div
        css={[
          padding('bottom', footerDistance),
          padding('top'),
          css`
            ${minWidth.tablet} {
              background: url('${background.image.fluid.src}') no-repeat 10rem
                center;
              background-size: cover;
            }

            @media (min-width: 1180px) {
              background-position: right center;
              background-size: 80%;
            }

            ${minWidth.full} {
              padding-top: 6.5rem;
            }

            @media (min-width: 1580px) {
              background-size: 61.25%;
            }
          `,
        ]}
      >
        <Wrapper>
          <div
            css={css`
              ${minWidth.tablet} {
                width: 60%;
              }

              ${minWidth.laptop} {
                width: 52.5%;
              }

              ${minWidth.full} {
                width: 45%;
              }
            `}
          >
            <h2
              css={css`
                ${minWidth.laptop} {
                  font-size: 2.3em;
                  line-height: 1.2;
                }
              `}
            >{settings.title}</h2>
            {settings.description.split("\n\n").map((text, index) => <p key={index}>{text}</p>)}
            <BookingButton color="green">
              Boeken & beschikbaarheid
            </BookingButton>
          </div>
        </Wrapper>
      </div>
    )}
  />
)
