import React from 'react'

export default class Anchor extends React.Component {
  scroll() {
    const target = document.querySelector(this.props.target)

    if (target) {
      target.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  render() {
    return (
      <div css={this.props.styles} onClick={() => this.scroll()}>
        {this.props.children}
      </div>
    )
  }
}
